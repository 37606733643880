<template>
  <div>
    <div class="flex mt-8">
      <div class="w-1/2 border px-4 py-4 mb-4 -mr-2 rounded text-sm">
        <div class="flex">
          <div class="mr-2">
            <h1 class="text-xl font-bold">Actual Balance</h1>
          </div>
        </div>
        <div class="flex text-xl mt-2 text-gray-600">
          GMD {{ formatPrice(data.balance) }}
        </div>
      </div>
      <div class="w-1/2 border px-4 py-4 mb-4 ml-4 rounded text-sm">
        <div class="flex">
          <div class="mr-2">
            <h1 class="text-xl font-bold">Total Sales</h1>
          </div>
        </div>
        <div class="flex text-xl mt-2 text-gray-600">
          GMD {{ formatPrice(data.total_sales.total / 100) }}
        </div>
      </div>
      <div class="w-1/2 border px-4 py-4 mb-4 ml-4 rounded text-sm">
        <div class="flex">
          <div class="mr-2">
            <h1 class="text-xl font-bold">Total Transactions</h1>
          </div>
        </div>
        <div class="flex justify-between">
          <div class="flex text-xl mt-2 text-gray-600">
            GMD {{ data.total_sales.count }}
          </div>
        </div>
      </div>
    </div>

    <div class="border p-4 rounded">
      <!-- <staff-sales /> -->
    </div>
  </div>
</template>

<script>
import FormatPrice from "@/mixins/FormatPrice";

export default {
  props: ["data"],

  mixins: [FormatPrice],

  data: () => ({
    isModalVisible: false,
    isLoading: false,
  }),

  methods: {
    showConfirmModal() {
      this.isModalVisible = true;
    },
    closeConfirmModal() {
      this.isModalVisible = false;
      this.$emit("complete");
    },

    confirmRequest(req) {
      console.log("User has confirmed", req);
      this.userHasConfirmed = req;

      if (this.userHasConfirmed == "yes") {
        // this.onSubmit();
      }
    },
  },
};
</script>
